import {post} from './request'
import { getBaseApi } from '../util'

const api = getBaseApi()

// 开奖号码
export function getWinNumber() {
    return post(`${api}/bonus/H5findNewsDraws.htm`)
}


export function isValid(data) {
    return data.code == 0 && data.data != void 0
}

// 余额
export function banlance() {
    return post(`${api}/member_operate/H5goUserFundDateil.htm`)
}

// 登录
export function login(data) {
    return post(`${api}/H5login.htm`, data, '登录中...')
}

// 资金明细
export function rechargeRecard(data) {
    return post(`${api}/memberCenter/member_operate/H5userFundDetail.htm`, data)
}

// 充值
export function recharge(data) {
    return post(`${api}/member_operate/payment/H5Recharge.htm`, data)
}

// 支付宝扫码充值
export function aliPay(data) {
    return post(`${api}/member_operate/payment/alipayRequest.htm`, data)
}


// 新增页面接口


// 首页
// 轮播图
export function swiperList(size=5) {
    return post(`${api}/article/logoNewsH5.htm?size=${size}`, {}, '', false)
}

// 新闻详情
export function newsDetail(id) {
return post(`${api}/article/detailsH5/${id}.htm`, {}, '', false)
}

// 新闻列表
export function newsList(data) {
    return post(`${api}/article/findArticleH5.htm`, data, '', false)
}

// 最近中奖
export function newWinningList(size = 20) {
    return post(`${api}/bonus/findNewsBonusH5.htm?size=${size}`, {}, '', false)
}

// 最新一期截止投注时间
export function openBonnusTime(gameId) {
    return post(`${api}/main/quickLotteryIssueH5.htm?gameId=${gameId}`, {}, '', false)
}

// 卡号登陆
export function loginByCardNum(data,) {
    return post(`${api}/H5login.htm`, data, '登录中...')
}

// 登出
export function loginout() {
    return post(`${api}/H5loginout.htm`, {}, '登出中...')
}


// 最新开奖时间
export function lastedBonusOpen(gameId) {
    return post(`${api}/bonus/findIssueVoH5.htm?gameId=${gameId}`, {}, '', false)
}

// 开奖号码列表
export function wincodeList(gameId = 11, size=30, loading = true) {
    return post(`${api}/bonus/findPrizeBulletinH5.htm?gameId=${gameId}&size=${size}`, {}, '加载中...', loading)
}


// 提现相关
// 修改提现密码
export function updateDeposit(data) {
    return post(`${api}/member_operate/doUpdatePayPwdH5.htm`, data, '修改中...')
}

// 提现信息
export function withdrawInfo(data) {
    return post(`${api}/member_operate/goUserDrawH5.htm`, data)
}

// 提现
export function withdraw(data) {
    return post(`${api}/memberCenter/member_operate/withdrawProcessH5.htm`, data, '提现中...', false)
}

// 获取市
export function getCity(id) {
    return post(`${api}/memberCenter/member_operate/findAreaH5/${id}.htm`)
}

// 添加银行卡
export function addBankCard(data) {
    return post(`${api}/memberCenter/member_operate/setBindAccountH5.htm`, data, '添加中...')
}

// 相关银行及省市
export function getBanksAndCitys() {
    return post(`${api}/memberCenter/member_operate/bindBank/onAddH5.htm`)
}

// 用户银行卡
export function userBankCards() {
    return post(`${api}/memberCenter/member_operate/searchBankCardH5.htm`)
}

// 银行卡解绑
export function unbindCard(id) {
    return post(`${api}/memberCenter/member_operate/removeBindAccountH5/${id}.htm`, {}, '解绑中...')
}

// 个人中心
// 余额及奖金
export function balanceAndBonus() {
    return post(`${api}/member_operate/allCapitalH5.htm`)
}

// 资金明细
export function fundDetail() {
    return post(`${api}/member_operate/goUserFundDateilH5.htm`, {}, '', false)
}

// 获取密码修改部分手机验证码
export function getPWAuthorCode(mobile) {
    return post(`${api}/goPwdRecoverByMobileH5.htm?mobile=${mobile}`, {}, '获取中...', false)
}

// 验证密码修改部分手机验证码
export function testPWAuthorCode(data) {
    return post(`${api}/doPwdRecoverByMobileH5.htm`, data, '提交中...')
}

// 未登录重置密码
export function resetPasswordNotLogin(data) {
    return post(`${api}/doResetPwdForRecoverPwdH5.htm`, data, '修改中...')
}

// 重置用户信息
export function resetUserInfo(data) {
    return post(`${api}/member_operate/doUpdateBaseinfoH5.htm`, data, '重置中...')
}

// 检测注册接口是否可用
export function testRegister() {
    return post(`${api}/register/checkRegisterValidH5.htm`)
}

// 注册
export function register(data) {
    return post(`${api}/registerH5.htm`, data, '注册中...')
}

// 获取通用手机验证码
export function getCommonAuthorCode(mobile) {
    return post(`${api}/getSendValidSMSH5.htm?mobile=${mobile}`, {}, '获取中...')
}

// 验证通用手机验证码
export function testCommonAuthorCode(data) {
    return post(`${api}/verificationCodeH5.htm`, data)
}
// 验证码登录
export function loginByPhone(data) {
    return post(`${api}/mobileLoginH5.htm`, data, '登录中...')
}

// 获取充值二维码
export function getRqCode() {
    return post(`${api}/payment/qrCodeH5.htm`)
}


// 个人中心
// 投注列表
export function lotteryRecard(data) {
    return post(`${api}/QueryCenter/member_operate/webWagerQueryListH5.htm`, data, '', true, false)
}

// 中奖投列表
export function winRecard(data) {
    return post(`${api}/QueryCenter/member_operate/webAccountBonusQueryListH5.htm`, data, '', true, false)
}

// 投注详情
export function lotteryDetail(data) {
    return post(`${api}/QueryCenter/member_operate/webAnteWinQueryDetailH5.htm`, data)
}

// 多期投注列表
export function termlyLotteryRecard(data) {
    return post(`${api}/QueryCenter/member_operate/webAccountInvestPlanQueryH5.htm`, data)
}

// 多期投注详情
export function termlyLotteryDetail(data) {
    return post(`${api}/QueryCenter/member_operate/webInvestPrintTicketQueryH5.htm`, data)
}

// 红包信息
export function redEnvInfo() {
    return post(`${api}/isReceivedRedMoneyH5.htm`, {}, '', false)
}

// 领取红包
export function getEnvInfo(data) {
    return post(`${api}/receiveRedMoneyH5.htm`, data)
}

// 用户行为
export function userOperation(data) {
    return post(`${api}/QueryCenter/member_operate/saveUserOperationLogH5.htm`, data)
}

// 强密码校验
export function checkPasswordApi(data) {
    return post(`${api}/checkPasswordValidatorH5.htm`, data)
}


